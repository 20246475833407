import React, { Component } from "react"
import MainLayout from '../components/MainLayout'
import styled from 'styled-components'
import bio from '../Images/bioImage.jpg'
import anime from 'animejs'
import { navigate } from "gatsby"

const Container = styled.div`
    width: ${props=> props.conWidth};
    height: ${props=> props.conHeight};
    background-color: ${props => props.bgColor};
    flex-direction: ${props=> props.flexdirection};
    flex-flow: ${props=> props.flexflow};
    display: flex;
    border-bottom-color: ${props=> props.bbColor};
    border-bottom-style: ${props=> props.bbStyle};
    border-bottom-width: ${props=> props.bbWdith};
    border-left-color: ${props=> props.lbColor};
    border-left-style: ${props=> props.lbStyle};
    border-left-width: ${props=> props.lbWidth};
    margin: ${props=> props.conMargin};
    justify-content: ${props=> props.justifyContent};
    align-items: ${props=> props.alignItems};
    align-content: ${props=> props.alignContent};
    padding: ${props=> props.conPadding};
    flex-grow: ${props=> props.flexGrow};
`
const BioImage = styled.img`
    width: 100%;
`

const P=styled.p`
    width:80%;
    margin-top:10%;
    margin-bottom: 10%;
    font-family: 'Raleway';
    font-size: 2.2vh;
    align-content: center;
    text-align: center;
    line-height: 145%;
`

const Button=styled.button`
    width: 80%;
    background-color: transparent;
    font-family: 'Raleway';
    font-size: 2vh;
    color: ${props=>props.txtColor};
    border-style: solid;
    border-color: ${props=>props.bColor};
    border-radius: 10px;
    height: 15%;
    :focus{
        outline: none;
    }
`

class IndexPage extends Component {
    animateAboutMeButton=(direction, duration)=>{
        anime({
            targets: '.aboutMeButton',
            backgroundColor: ['#CAE9FF','#5FA8D3'],
            borderColor: ['#1B4965','#5FA8D3'],
            easing: 'linear',
            color: ['#1B4965', '#FFF'],
            duration: duration,
            direction: direction
        })
    }
    animateBlogButton=(direction, duration)=>{
        anime({
            targets: '.blogButton',
            backgroundColor:['#5FA8D3','#CAE9FF'],
            borderColor: ['#FFF','#CAE9FF'],
            easing: 'linear',
            color: ['#FFF','#1B4965'],
            duration: duration,
            direction: direction
        })
    }
    goToNextPage=(path)=>{
        navigate(path);
    }
    
    render(){
    return(
        <MainLayout>
            <style>@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');</style> 
            <Container justifyContent={'center'} flexdirection={'column'} conWidth={'25%'}>
              <BioImage src={bio}/>
            </Container>
            <Container flexdirection={'column'} flexGrow={'1'} conWidth={'75%'}>
                <Container bgColor={"#CAE9FF"}  bbStyle={'solid'} conHeight={'50%'} bbColor={'#1B4965'} bbWdith={'1px'} flexdirection={'row'} conWidth={'100%'}>
                    <Container flexdirection={'column'} conWidth={'55%'} conMargin={'0 10% 0 5%'} alignItems={'center'} justifyContent={'center'}>
                        <P>
                        My name is Corey Wilkins, and I'm an IT professional located in the central valley of California! This site represents
                        a living portfolio of my work and who I am as an IT professional. I'm currently working in the health care sector as a 
                        Network and Systems Engineer. My interests lie in enterprise networking and cloud architecture. If I'm not working
                        on learning either of those, I like to play video games, try to play the piano, go to music festivals, 
                        and hang out with friends!
                        </P>
                    </Container>
                    <Container flexdirection={'column'} conWidth={'25%'} conMargin={'0 5% 0 0'} justifyContent={'center'} alignItems={'center'}>
                        <Button className='aboutMeButton' onClick={()=>{this.goToNextPage('/resume')}} onMouseEnter={()=>{this.animateAboutMeButton('normal',500)}} onMouseLeave={()=>{this.animateAboutMeButton('reverse',1000)}} bColor={'#1B4965'} txtColor={'#1B4965'}>Learn More About Me!</Button>
                    </Container>
                </Container>
                <Container bgColor={"#5FA8D3"} conHeight={'50%'} flexdirection={'row'} conWidth={'100%'}>
                    <Container flexdirection={'column'} conWidth={'25%'} conMargin={'0 10% 0 5%'} justifyContent={'center'} alignItems={'center'}>
                        <Button className='blogButton' onClick={()=>{this.goToNextPage('/blog')}} bColor={'white'} txtColor={'white'} onMouseEnter={()=>{this.animateBlogButton('normal',500)}} onMouseLeave={()=>{this.animateBlogButton('reverse',1000)}}>To the Blog!</Button>
                    </Container>
                    <Container flexdirection={'column'} conWidth={'55%'} conMargin={'0 5% 0 0'} alignItems={'center'} justifyContent={'center'}>
                    <P>
                        I have a blog! Well kinda...it's more of a collection of my interests and curiosities! If you want to check out what I'm
                        currently working on or diving into on my free time, this is the place. I cover anything from basic how-tos for programming
                        to technical deep dives on advanced topics like routing protocols and IaaS. Pretty much anything I have a curiosity in!
                         Go check it out!    
                     </P>
                    </Container>
                </Container>
            </Container>
        </MainLayout>
    )}
}

export default IndexPage
